import { createI18n } from 'vue-i18n'
import store from '../store'
export default createI18n({
    legacy: true, // 让 setup 函数可以通过 t 访问
    globalInjection: true, // 让 template 可以像 vue2 那样使用 $t 来访问
    locale:  store.state.lang ?  store.state.lang:"zh" ,
    silentTranslationWarn:true,
    allowComposition: true,
    silentFallbackWarn: true,
    messages: {
        'zh': require('./lang/zh.json'),
        'en': require('./lang/en.json'),
        'cht': require('./lang/cht.json'),
        'jp': require('./lang/jp.json'),
        'kor': require('./lang/kor.json'),
        'ind': require('./lang/ind.json'),

    }
})