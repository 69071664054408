<template>
  <router-view  v-if="isRouterAlive" />
</template>

<script>
import { reactive, toRefs, onMounted,nextTick } from 'vue'
import {useStore} from 'vuex'
export default {
  name: 'App',
  components:{
    
  },
  setup() {
    const data = reactive({
       isRouterAlive:true,
    })

    const reload =  ()=>{
       data.isRouterAlive = false
       nextTick(()=>{
         data.isRouterAlive = true;
       })
    }

    const addNetwork = () => {
        window.ethereum.request({
            method: 'wallet_addEthereumChain', // Metamask的api名称
            params: [{
                chainId: "0x38", // 网络id，16进制的字符串
                chainName: "BNB Smart Chain", // 添加到钱包后显示的网络名称
                rpcUrls: [
                    'https://bsc-dataseed.binance.org/', // rpc地址
                ],
                blockExplorerUrls: [
                    'https://bscscan.com' // 网络对应的区块浏览器
                ],
                nativeCurrency: {  // 网络主币的信息
                    name: 'BNB',
                    symbol: 'BNB',
                    decimals: 18
                }
            }]
        })
    }

    const store = useStore();
      onMounted(() => {
        if(window.ethereum){
            let  ethereum = window.ethereum; 
           addNetwork();
            ethereum.on('accountsChanged', function (accounts) {
              store.commit('setAccount',accounts[0]);
              reload();
            })
        }
      })
    return {
      ...toRefs(data),
      reload,
      addNetwork
    }
  },
}

</script>

<style lang="scss">
  #app {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(0deg , #09111F,#0D1D3C);
  }


</style>
