import { createRouter,createWebHashHistory} from "vue-router";
const Home  =  ()=> import('../page/index');
const Inls  =  ()=> import('../page/inls');
const Lrls  =  ()=> import('../page/lrls');
const Team  =  ()=> import('../page/team');



const routes = [
  {
    path: "/",
    name: "Home",
    component:Home,
  },
  {
    path: "/inls",
    name: "Inls",
    component:Inls
  },
  {
    path: "/lrls",
    name: "Lrls",
    component:Lrls
  },
  {
    path: "/team",
    name: "Team",
    component:Team
  },

]




const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
  })


export default router;