import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vant from 'vant';
import { Dialog,Calendar  } from 'vant';
import 'vant/lib/index.css';
import store from './store';
import i18n from './language/index'
const app = createApp(App);
import Video from "video.js";
import "video.js/dist/video-js.min.css"
app.config.globalProperties.$video = Video
app.config.globalProperties.productionTip = false;

app.use(i18n)
app.use(store)
app.use(vant)
app.use(router)
app.use(Dialog);
app.use(Calendar);
app.mount("#app");

