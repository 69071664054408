
import Vuex from 'vuex'

const state = {
    lang: localStorage.getItem('LANG') ||  "en",
    title: '人员列表',
    token:"",
    uid:"",
    account:"",
    // BASE:"https://testtsl.metatsl.top/banner/"
    BASE:"https://api.metatsl.top/banner/",
    walletType:0

}

const mutations = {
    setState (state, data){
        state.title = data
    },
    setToken (state, data){
        state.token = data
    },
    setUid (state, data){
        state.uid = data
    },
    setLang (state, data){
        localStorage.setItem('LANG',data)
        state.lang = data
    },
    setAccount (state,data){
        state.account = data
    },

    setWalletType (state,data){
        state.walletType = data
    }
}

const getters = {

}

export default new Vuex.Store({
    state,
    mutations,
    getters
})